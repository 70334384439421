
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticProps, GetStaticPropsContext } from 'next';
import styled from 'styled-components';
import getReviews from 'lib/cache/latestReviews/reviews';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import { Review } from 'shared/api/latestReviews';
import useTranslation from 'shared/hooks/useTranslation';
const Page = dynamic(() => import('shared/components/Page'), { ssr: false });
const MainSectionVideo = dynamic(() => import('modules/home/v2/MainSectionVideo'), { ssr: false });
const HeaderSection = dynamic(() => import('modules/sellsPage/v2/HeaderSection'), { ssr: false });
const ComparisonSection = dynamic(() => import('modules/home/v2/ComparisonSection'), { ssr: false });
const SimpleDecision = dynamic(() => import('modules/home/v2/SimpleDecision'), { ssr: false });
const LogoSection = dynamic(() => import('modules/home/v2/LogoSection'), { ssr: false });
const AnimationSection = dynamic(() => import('modules/home/v2/AnimationSection'), { ssr: false });
const InvitationStatistics = dynamic(() => import('modules/home/v2/InvitationStatistics'), { ssr: false });
const IncreaseSales = dynamic(() => import('modules/home/v2/IncreaseSales'), { ssr: false });
const HydraSection = dynamic(() => import('modules/home/v2/HydraSection'), { ssr: false });
const IntegrationsSection = dynamic(() => import('modules/sellsPage/v2/components/Integrations'), { ssr: false });
const DotSlideSection = dynamic(() => import('modules/home/v2/DotSlideSection'), { ssr: false });
const GridSection = dynamic(() => import('modules/home/v2/GridSection'), { ssr: false });
const TestimonialSection = dynamic(() => import('modules/home/v2/TestimonialSection'), { ssr: false });
const MainWithFont = styled.main `
  font-family: ${({ theme }) => theme.FONTS.MAIN};
`;
type HomePageProps = {
    latestReviews: Review[];
};
function HomePage({ latestReviews }: HomePageProps): JSX.Element {
    const { t } = useTranslation('integrationsPage');
    return (<>
      <Head>
        {process.env.NEXT_PUBLIC_APP_ENV === 'prod' ? (<Script id="hotjar">
            {`
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2707955,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}
          </Script>) : null}
      </Head>
      <Page reviews={latestReviews} withFooter withLatestReviews>
        <MainWithFont suppressHydrationWarning>
          <MainSectionVideo />
          <ComparisonSection />
          <HydraSection />
          <SimpleDecision />
          <AnimationSection />
          <LogoSection />
          <GridSection />
          <IncreaseSales />
          <TestimonialSection />
          <IntegrationsSection>
            <HeaderSection header={t('fastStart')} description={t('platformSupport')}/>
          </IntegrationsSection>
          <InvitationStatistics />
          <DotSlideSection />
        </MainWithFont>
      </Page>
    </>);
}
const getStaticProps: GetStaticProps<HomePageProps> = async (context: GetStaticPropsContext) => {
    const latestReviews = await getReviews({
        language: context.locale as string,
    });
    return {
        props: { latestReviews },
        revalidate: 60,
    };
};
export default HomePage;

    async function __Next_Translate__getStaticProps__19208ef65b3__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19208ef65b3__ as getStaticProps }
  